<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('screen', 'edit') || permissionEnabled('screen', 'delete')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :class="l.color ? l.color : 'has-text-grey-light'" v-show="showButtons(l.type)" @click="handleClick(l, id)">
			<svg-icon :icon="l.icon"></svg-icon>
			<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		active: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			screen: {},
			btn: true,
			items: [
				{
					id: 1,
					name: 'edit',
					icon: 'edit',
					type: 'edit'
				},
				{
					id: 2,
					name: 'delete' ,
					icon: 'trash',
					color: 'has-text-danger',
					type: 'delete'
				},
				{
					id: 3,
					name: this.active ? 'disable' : 'activate',
					icon: this.active ? 'restore' : 'restore',
					color: 'has-text-info',
					type: 'edit'
				}
			]
		}
	},
	methods: {
		handleClick(el, id) {
			const name = el.id
			if (name === 1) {
				eventHub.$emit('edit-modal-screens', { id })
			} else if (name === 2) {
				eventHub.$emit('delete-screens', { id })
			} else if (name === 3) {
				eventHub.$emit('enable-disable', { id: id, active: el.name == 'disable' ? 0 : 1 })
			}
		},
		showButtons(type) {
			let show = false

			if (this.permissionEnabled('screen', type)) {
				show = true
			}

			return show
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
