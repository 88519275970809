export const TYPE_IMAGEM           = 1;
export const TYPE_TITLE_TEXT_IMAGE = 2;
export const TYPE_VIDEO_UPLOAD     = 3;
export const TYPE_LINK_EXTERNAL    = 4;
export const TYPE_EVENT_ICS        = 5;
export const TYPE_HEADER_IMAGE     = 6;
export const TYPE_HEADER_TEXT      = 7;
export const TYPE_HEADER_BLOCKS    = 8;
export const TYPE_HEADER_LIST      = 9;

export const templateTypes = [
    { id: TYPE_IMAGEM, name: 'Imagem' },
    { id: TYPE_TITLE_TEXT_IMAGE, name: 'Título, Texto e Imagem' },
    { id: TYPE_EVENT_ICS, name: 'Evento via ICS' },
    { id: TYPE_HEADER_IMAGE, name: 'Cabeçalho com Imagem' },
    { id: TYPE_HEADER_TEXT, name: 'Cabeçalho com Texto' },
    { id: TYPE_HEADER_BLOCKS, name: 'Cabeçalho com Blocos' },
    { id: TYPE_HEADER_LIST, name: 'Cabeçalho com Listas' },
    { id: TYPE_VIDEO_UPLOAD, name: 'Upload de Vídeo' },
    { id: TYPE_LINK_EXTERNAL, name: 'Link Externo' }
]

export const urlServer = () => {
    let urlApi = process.env.VUE_APP_BASE_API

    return urlApi.substring(0, urlApi.length - 5)
}

export const screenAttributesFields = {
    6: { // Cabeçalho com Imagem
        multiple: false,
        maxSize: 1,
        fields: [
            { type: 'text', key: 'title', required: true },
            { type: 'image', key: 'image', required: true }
        ]
    },
    7: { // Cabeçalho com Texto
        multiple: false,
        maxSize: 1,
        fields: [
            { type: 'text', key: 'title', required: true },
            { type: 'editor', key: 'text', required: true }
        ]
    },
    8: { // Cabeçalho com Blocos
        multiple: true,
        maxSize: 14,
        fields: [
            { type: 'image', key: 'image', required: true },
            { type: 'text', key: 'title', required: true },
            { type: 'text', key: 'description', required: false },
            { type: 'text', key: 'circle', required: false }
        ]
    },
    9: { // Cabeçalho com Listas
        multiple: true,
        maxSize: 6,
        fields: [
            { type: 'text', key: 'title', required: true },
            { type: 'text', key: 'description', required: false },
            { type: 'text', key: 'text', required: false }
        ]
    }
}
