<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $t('screen') }}</strong></span>
					<span v-if="screen.id">#{{ screen.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<div class="columns">
						<div class="column">
							<InputWithValidation name="name" rules="required|min:3" type="text" :label="$t('name')" v-model="screen.name" />
						</div>
						<div class="column">
							<b-field class="mb-4" :label="$t('template')">
								<multiselect
									v-model="screen.template_id"
									tag-position="top"
									label="name"
									track-by="name"
									:max-height="222"
									:options="templates"
									:close-on-select="true"
									:hide-selected="true"
									:searchable="false"
									placeholder=""
									selectLabel=""
									selectedLabel=""
									deselectLabel="">
									<span slot="noResult">Nenhum template encontrado!</span>
									<template class="types" slot="option" slot-scope="props">
										<img class="option__image" :src="`${getUrlServer()}/assets/images/type-${getTemplateType(props.option)}.png`">
										<span class="option__name">{{ props.option.name }}</span>
									</template>
								</multiselect>
							</b-field>
						</div>
					</div>

					<div v-if="screen.template_id && screen.template_id !== 1" class="modal-frame mb-5">
						<span v-if="[6, 8, 9].includes(getTemplateType(screen.template_id))">
							<hr class="my-3 has-background-white">
						</span>
						<div v-if="[2, 8, 9].includes(getTemplateType(screen.template_id))">
							<InputWithValidation class="mb-4" name="title" rules="required|min:2" type="text" :label="$t('title')" v-model="screen.title" />
						</div>

						<div v-if="getTemplateType(screen.template_id) == 2">
							<b-field class="mb-4" :label="$t('text')">
								<vue-editor v-model="screen.text"></vue-editor>
							</b-field>
						</div>

						<b-field v-if="showUpload(screen.template_id)" class="file" :class="{'has-name': !!screenFile}">
							<b-upload v-model="screenFile">
								<span class="file-cta">
									<span class="file-label">
										{{ $t('file') }}
									</span>
								</span>
								<span class="file-name has-background-white" v-if="screenFile">
									{{ screenFile.name }}
								</span>
							</b-upload>
						</b-field>

						<b-field v-if="showUploadImage(screen.template_id)">
							<CropImage id="image-screen" v-model="screenImage" />
						</b-field>

						<!--
							<b-upload v-if="[2, 3].includes(getTemplateType(screen.template_id))" class="button is-secondary" v-model="screenFile">
							Selecione o arquivo
							</b-upload>
						-->

						<InputWithValidation v-if="[4, 5].includes(getTemplateType(screen.template_id))" class="mb-4" name="name" icon-right="link" rules="required|min:3" type="url" :label="$t('link')" v-model="screen.url" />

						<div v-if="screen.template_id && getTemplateType(screen.template_id) >= 6" class="modal-frame darken">
							<span v-if="[8, 9].includes(getTemplateType(screen.template_id))">
								<h2 class="is-size-5 is-semibold has-text-primary">Itens</h2>
								<hr class="mt-3 has-background-white mb-5-5">
							</span>
							<ScreenAttribute :templateType="getTemplateType(screen.template_id)" :attributesTemp="attributesTemp" v-model="screen.attributes" />
						</div>
				</div>

					<h3 class="is-size-6 is-semibold has-text-primary">{{ $t('display_settings') }}</h3>
					<hr class="my-3">
					<div class="columns">
						<div class="column is-two-thirds">
							<b-field :label="$t('places')">
								<multiselect v-model="screen.places" name="delaers" :options="places" label="name" track-by="name" placeholder="" multiple preserve-search open-direction="top" :maxHeight="250" :close-on-select="false" :clear-on-select="false" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
									<span slot="noResult">{{ $t('no_places') }}</span>
								</multiselect>
							</b-field>
						</div>
						<div class="column">
							<b-field :label="$t('duration')">
								<b-numberinput class="seconds" v-model="screen.duration" type="is-light" :min="1"></b-numberinput>
							</b-field>
						</div>
					</div>
					<div class="columns">
						<div class="column mb-3">
							<InputWithValidation name="date_start" rules="required|min:3" type="date" :label="$t('start')" v-model="screen.date_start" />
						</div>
						<div class="column mb-3">
							<InputWithValidation name="date_end" rules="required|min:3" type="date" :label="$t('end')" v-model="screen.date_end" />
						</div>
					</div>

					<small class="modal-updated" v-if="screen.updated_at">{{ $t('last_change') }} {{ format(screen.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import {
	TYPE_IMAGEM,
	TYPE_TITLE_TEXT_IMAGE,
	TYPE_VIDEO_UPLOAD
} from '@/services/constants'
import server from '@/mixins/server'
import ScreenAttribute from '@/components/inputs/ScreenAttribute'
import CropImage from '@/components/inputs/CropImage'
import { VueEditor } from 'vue2-editor'

/*
TIPOS DE TEMPLATE

1 = 'Imagem',
2 = 'Título, Texto e Imagem',
3 = 'Upload de Vídeo',
4 = 'Link Externo',
5 = 'Evento via ICS',
*/

export default {
	mixins: [server],
	components: {
		InputWithValidation,
		Multiselect,
		ValidationObserver,
		ScreenAttribute,
		CropImage,
		VueEditor
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			hide: {
				title: {
					ol: true,
					ul: true,
					url: true,
					table: true
				},
				text: {}
			},
			screen: {
				duration: 6
			},
			screenFile: null,
			screenImage: null,
			role: 1,
			permission: [],
			visible: false,
			templates: [],
			places: [],
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			attributesTemp: null
		}
	},
	methods: {
		async getTemplatesAndPlaces() {
			try {
				this.loading = true
				this.isOpening = true

				const resTemplate = await Api.get('template/findAll')

				if (resTemplate.status === 200) {
					this.templates = resTemplate.data
				}

				const resPlace = await Api.get('place/findAll')

				if (resPlace.status === 200) {
					this.places = resPlace.data
				}

				this.findById()
			} catch(e) {
				console.log(e)
			} finally {
				this.loading = false
				this.isOpening = false
			}
        },
		async update() {
			try {
				this.loading = true
				let formData = this.getValuesForm()
				formData.append('_method', 'put')

				const response = await Api.post(`screen/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if (status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/screens')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-screens')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('saved_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`screen/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.screen = data
						this.screen.template_id = this.templates.find(t => t.id == data.template_id)
						this.isOpening = false

						if (data.screen_attributes) {
							this.attributesTemp = JSON.parse(data.screen_attributes.value)
						}
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}
			}
		},
		async store() {
			try {
				this.loading = true
				let formData = this.getValuesForm()

				const response = await Api.post('screen/store', formData, this.configRequest)
				const { status } = response
				if (status === 201 || status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/screens')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-screens')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('saved_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		getValuesForm() {
			let formData = new FormData()

			formData.append('name', this.screen.name)
			formData.append('title', this.screen.title || '')
			formData.append('text', this.screen.text || '')
			formData.append('duration', this.screen.duration)
			formData.append('date_start', this.screen.date_start)
			formData.append('date_end', this.screen.date_end)

			if ([4, 5].includes(this.getTemplateType(this.screen.template_id))) {
				formData.append('url', this.screen.url || '')
			}

			if (this.screen.template_id) {
				formData.append('template_id', this.screen.template_id.id);
			}

			if (!this.screen.places || this.screen.places.lenght == 0) {
				errorToast(this.$t('places_empty_error'))
				return
			} else {
				this.screen.places.forEach((s, i) => {
					formData.append('places[' + i + ']', s.id)
				})
			}

			if (this.screenFile) {
				formData.append('file', this.screenFile)
			}

			if (this.screenImage) {
				formData.append('image[file]', this.screenImage.file)

				if (this.screenImage.crop) {
					formData.append('image[crop][width]', this.screenImage.crop.width)
					formData.append('image[crop][height]', this.screenImage.crop.height)
					formData.append('image[crop][x]', this.screenImage.crop.x)
					formData.append('image[crop][y]', this.screenImage.crop.y)
				}
			}

			if ([6, 7, 8, 9].includes(this.getTemplateType(this.screen.template_id))) {
				formData.append('attributes[multiple]', this.screen.attributes.multiple ? 1 : 0)

				if (this.screen.attributes.multiple) {
					this.screen.attributes.values.forEach((v, i1) => {
						v.forEach((f, i2) => {
							let valueFile = f.value

							if (f.type == 'image') {
								if (!valueFile && f.file_url) {
									valueFile = f.file_url
								}
							}

							formData.append('attributes[values][' + i1 + '][' + i2 + '][key]', f.key)
							formData.append('attributes[values][' + i1 + '][' + i2 + '][value]', valueFile || '')
							formData.append('attributes[values][' + i1 + '][' + i2 + '][required]', f.required ? 1 : 0)
							formData.append('attributes[values][' + i1 + '][' + i2 + '][type]', f.type)

							if (f.crop) {
								formData.append('attributes[values][' + i1 + '][' + i2 + '][crop][width]', f.crop.width)
								formData.append('attributes[values][' + i1 + '][' + i2 + '][crop][height]', f.crop.height)
								formData.append('attributes[values][' + i1 + '][' + i2 + '][crop][x]', f.crop.x)
								formData.append('attributes[values][' + i1 + '][' + i2 + '][crop][y]', f.crop.y)
							}
						})
					})
				} else {
					this.screen.attributes.fields.forEach((f, i) => {
						let valueFile = f.value

						if (f.type == 'image') {
							if (!valueFile && f.file_url) {
								valueFile = f.file_url
							}
						}

						formData.append('attributes[fields][' + i + '][key]', f.key)
						formData.append('attributes[fields][' + i + '][value]', valueFile || '')
						formData.append('attributes[fields][' + i + '][required]', f.required ? 1 : 0)
						formData.append('attributes[fields][' + i + '][type]', f.type)

						if (f.crop) {
							formData.append('attributes[fields][' + i + '][crop][width]', f.crop.width)
							formData.append('attributes[fields][' + i + '][crop][height]', f.crop.height)
							formData.append('attributes[fields][' + i + '][crop][x]', f.crop.x)
							formData.append('attributes[fields][' + i + '][crop][y]', f.crop.y)
						}
					})
				}
			}

			return formData
		},
		async save() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		getTemplateType(template) {
			if (template && (typeof template == 'object')) {
				const templateLocal = this.templates.find(t => t.id == template.id)
				return templateLocal.type
			}

			return 0
		},
		showUpload(template) {
			if ([TYPE_VIDEO_UPLOAD].includes(template.type)) {
				return true
			}

			return false
		},
		showUploadImage(template) {
			if ([TYPE_IMAGEM, TYPE_TITLE_TEXT_IMAGE].includes(template.type)) {
				return true
			}

			return false
		}
	},
	mounted() {
		this.getTemplatesAndPlaces()
	}
}
</script>
