<template>
	<Layout>
		<Header>
			<b-button type="is-secondary export" size="is-small" rounded outlined :disabled="screens.length == 0" @click="openOrdering()">
				<svg-icon class="icon is-small" icon="order"></svg-icon>
				<span>{{ $t('order_button') }}</span>
			</b-button>
			<b-button v-if="permissionEnabled('screen', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportScreen($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('export') }}</span>
			</b-button>
			<b-button v-if="permissionEnabled('screen', 'create')" type="is-primary create" rounded @click="create($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field label="Ordem" :label-position="label">
					<b-select :placeholder="$t('name')" @input="filterByOrder">
						<option selected value="1">{{ $t('name') }}</option>
						<option value="2">{{ $t('date') }}</option>
						<option value="3">{{ $t('presentation') }}</option>
					</b-select>
				</b-field>
				<b-field label="Status" v-model="filter.status" :label-position="label">
					<b-select :placeholder="$t('active')" @input="filterByStatus">
						<option selected value="1">{{ $t('active') }}</option>
						<option value="2">{{ $t('inactive') }}</option>
					</b-select>
				</b-field>
				<b-field :label="$t('place')" v-model="filter.place" :label-position="label">
					<b-select :placeholder="$t('select')" @input="filterByPlace">
						<option v-for="p in places" :key="`p-${p.id}`" :value="p.id">{{ p.name }}</option>
					</b-select>
				</b-field>
				<b-field>
					<b-input :placeholder="$t('search')" type="search" icon-right="magnify" v-model="filter.name" @input="findByName"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="screens.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-4-tablet is-3-desktop is-2-widescreen">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="s in screens" :key="s.id" class="list-item column is-12-mobile is-4-tablet is-3-desktop is-2-widescreen">
				<article class="block image no-cover" @click.self="update(s.id)">
					<div class="block__cover">
						<img :src="showImage(s)">
					</div>
					<div class="block__content">
						<h3 class="block__name" translate="no" :title="s.name">{{ s.name }}</h3>
						<p class="block__email">{{ format(s.created_at) }} • {{ timeTo(s.created_at) }}</p>
					</div>
					<span v-if="s.status" class="block__copy">
						<span :class="`block__id status ${s.status}`">{{ $t(s.status) }}</span>
					</span>
					<Trigger :id="s.id" :active="s.active" />
				</article>
			</div>
		</transition-group>
		<div class="notifications__list" :class="{ active: ordering }">
			<span class="notifications__head">
				<span>{{ $t('ordering') }}</span>
				<svg @click="closeOrdering()" class="icon-button stroke" viewBox="0 0 24 24">
					<path d="M4.222 4.222l15.556 15.556M4.222 19.778L19.778 4.222"></path>
				</svg>
			</span>
			<b-loading v-model="reordering" :is-full-page="false"></b-loading>
			<draggable
				:list="screensOrdenation"
				:disabled="screensOrdenation.length == 0"
				tag="ul"
				class="scroll"
        		@end="draggableEnd">
				<li class="drag" v-for="s in screensOrdenation" :key="s.name">
					<span class="item">
						<span>
							<h3 class="notifications__list__title">{{ s.name }}</h3>
							<p class="notifications__list__description">
								<span v-for="(p, i) in s.places" :key="p.id">{{ `${i > 0 ? `, ${p.name}` : p.name}` }}</span>
							</p>
						</span>
						<svg-icon class="icon ordering is-small" icon="order"></svg-icon>
					</span>
				</li>
			</draggable>
		</div>
		<span class="header__overlay" :class="{ 'opened pointer': ordering }" @click="closeOrdering()"></span>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Trigger from '@/components/triggers/Screen'
import Placeholder from '@/components/placeholders/Template'
import Api from '@/services/api'
import Modal from '@/components/modals/Screen'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { responsive } from '@/mixins/responsive'
import { mapGetters, mapState } from 'vuex'
import { successToast, errorToast } from '@/mixins/toast'
import draggable from "vuedraggable"
import {
	urlServer,
	TYPE_IMAGEM,
	TYPE_TITLE_TEXT_IMAGE,
	TYPE_VIDEO_UPLOAD,
	TYPE_HEADER_IMAGE
} from '@/services/constants'

export default {
	name: 'Screen',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Error,
		Results,
		Trigger,
		Placeholder,
		'svg-icon': Icon,
		draggable
	},
	data() {
		return {
			label: 'on-border',
			ordering: false,
			reordering: false,
			loading: true,
			errored: false,
			exporting: false,
			pagination: 12,
			screens: [],
			filter: {
				order: '',
				status: '1',
				place: '',
				name: ''
			},
			screensOrdenation: [],
			places: []
		}
	},
    mounted() {
		this.getPlaces()
        this.findAll()

		eventHub.$on('edit-modal-screens', obj => {
			update(this, 'screens', obj.id, Modal, 'Edit', obj.root)
		})

		eventHub.$on('delete-screens', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention'),
				message: '<span>' + this.$t('alert_delete') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`screen/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('deleted_success'))
							await this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('deleted_error'))
					}
				}
			})
		})

		eventHub.$on('enable-disable', obj => {
			if (!obj.active) {
				this.$buefy.dialog.alert({
					size: 'is-delete',
					type: 'is-outlined is-primary',
					title: this.$t('attention'),
					message: '<span>' + this.$t('alert_disabled_screen') + '</span>',
					canCancel: true,
					focusOn: 'cancel',
					cancelText: this.$t('no'),
					confirmText: this.$t('yes'),
					onConfirm: async () => {
						this.enableDisable(obj)
					}
				})
			} else {
				this.enableDisable(obj)
			}
		})

		eventHub.$on('reload-screens', () => {
			this.findAll()
		})
    },
	methods: {
		getPlaces() {
			Api.get('place/findAll')
                .then(({ data }) => {
                    this.places = data
                })
                .catch((err) => {
                    console.log(err)
                })
		},
		openOrdering() {
			if (this.ordering == true)
				return false
			this.ordering = true
			this.listScreensSorting()
		},
		closeOrdering() {
			this.ordering = false
			this.screensOrdenation = []
		},
        findAll() {
			this.screens = []
			this.loading = true
            Api.get('screen/findAll')
                .then(({ data }) => {
                    this.screens = data
                })
                .catch((err) => {
                    console.log(err)
                    errorToast('Ocorreu um erro ao buscar todos')
                })
				.finally(() => {
					this.loading = false
				})
        },
		async listScreensSorting() {
			this.reordering = true
			try {
				this.screensOrdenation = []
				const response = await Api.post('screen/filterByOrder', {
					name: 3,
					status: 1
				})

				if (response.status === 200) {
					this.screensOrdenation = response.data
					this.reordering = false
				}
			} catch (e) {
				this.reordering = false
				console.log(e)
			}
		},
		async draggableEnd() {
			this.reordering = true
			try {
				const response = await Api.post('screen/orderPresentation', { screens: this.screensOrdenation })

				if (response.status === 200) {
					this.reordering = false
					successToast(this.$t('sorted_successfully'))
				}
			} catch (e) {
				console.log(e)
				this.reordering = false
				errorToast(this.$t('error_sorting'))
			}
		},
		async filterByOrder(e) {
			try {
				this.loading = true
				this.filter.order = e
				const response = await Api.post('screen/filterByOrder', {
					name: this.filter.order,
					status: this.filter.status ? this.filter.status : null
				})

				if (response.status === 200) {
					this.screens = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByStatus(e) {
			try {
				this.loading = true
				this.filter.status = e
				const response = await Api.post('screen/filterByStatus', {
					name: this.filter.status
				})
				const { status } = response
				if (status === 200) {
					this.screens = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterByPlace(e) {
			try {
				this.loading = true
				this.filter.place = e
				const response = await Api.post('screen/filterByPlace', {
					place: this.filter.place,
					status: this.filter.status ? this.filter.status : null
				})
				const { status } = response
				if (status === 200) {
					this.screens = response.data
				}
			} catch (ex) {
				console.log(ex)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				this.loading = true
				const empty = /^\s*$/
				if (!empty.test(this.filter.name)) {
					const response = await Api.post('screen/findByName', {
						name: this.filter.name,
						status: this.filter.status ? this.filter.status : null
					})
					if (response.status === 200) {
						this.screens = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		update(id) {
			if (this.permissionEnabled('screen', 'edit')) {
				update(this, 'screens', id, Modal, 'Edit')
			}
		},
		create() {
			if (this.permissionEnabled('screen', 'create')) {
				create(this, 'screens', Modal, 'New')
			}
		},
		async exportScreen() {
			this.exporting = true
			try {
				const response = await Api.post('screen/export')
				const { status } = response
				if (![200, 201].includes(status)) {
					errorToast(this.$t('exported_error'))
				} else {
					const { message } = response.data
					successToast(this.$t('exported_success'))
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		},
		showImage(screen) {
			if ([TYPE_IMAGEM, TYPE_TITLE_TEXT_IMAGE, TYPE_VIDEO_UPLOAD, TYPE_HEADER_IMAGE].includes(screen.template.type)) {
				return screen.file_url
			}

			return `${urlServer()}/assets/images/type-${screen.template.type}.png`
		},
		async enableDisable(params) {
			try {
				const response = await Api.post(`screen/enableDisable`, params)
				if (response.status === 200) {
					successToast(this.$t(params.active ? 'successfully_activated' : 'successfully_deactivated'))
					this.findAll()
				}
			} catch (e) {
				console.log(e)
				errorToast(this.$t(params.active ? 'error_activating' : 'error_deactivating'))
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	},
	destroyed() {
		eventHub.$off('edit-modal-screens')
		eventHub.$off('delete-screens')
		eventHub.$off('reload-screens')
		eventHub.$off('enable-disable')
	}
}
</script>
