import { urlServer } from '@/services/constants'

export default {
	methods: {
		getUrlServer() {
			return urlServer()
		},
		getUrlPreview(placeCode, desktop = true) {
			return `${this.getUrlServer()}/access/${placeCode}/preview${desktop ? '?desktop=1' : ''}`
		}
	}
}
