<template>
    <div v-if="attribute" class="mb-5">
        <div v-if="attribute.multiple">
            <div class="frame-item pt-5" v-for="(values, indexArray) in attrMultiple.values" :key="`MUL-${indexArray}`">
								<span class="frame-number">{{ indexArray + 1 }}</span>
                <div v-for="(field, index) in values" :key="`MUL1-${index}`">
                    <InputWithValidation v-if="field.type == 'text'" class="mb-4" name="title" :rules="`${ field.required ? 'required|' : ''}min:2`" type="text" :label="$t(field.key)" v-model="attrMultiple.values[indexArray][index].value" horizontal />

                    <b-field v-if="field.type == 'editor'" class="mb-4" :label="$t('text')">
                        <vue-editor v-model="attrMultiple.values[indexArray][index].value"></vue-editor>
                    </b-field>

                    <b-field v-if="field.type == 'image'" class="mb-5">
                        <!-- <b-upload v-model="attrMultiple.values[indexArray][index].value">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ $t('file') }}
                                </span>
                            </span>
                        </b-upload> -->
                        <div class="avatar">
                            <cropper
                                :src="attrMultiple.values[indexArray][index].image"
                                @change="change(indexArray, index, ...arguments)"
                                 :stencil-props="getAspectRatio()"
                            />
                            <input type="file" :id="`img-${indexArray}-${index}`" hidden accept="image/*" @change="onFileChange(indexArray, index)" />
                            <b-button
                                v-if="attrMultiple.values[indexArray][index].added"
                                native-type="button"
                                class="avatar__edit"
                                @click="choosePhoto(indexArray, index)">
                                <svg-icon icon="edit"></svg-icon>
                            </b-button>
                            <b-button
                                v-if="!attrMultiple.values[indexArray][index].added"
                                native-type="button"
                                class="avatar__add"
                                @click="choosePhoto(indexArray, index)">
                                <svg-icon icon="upload"></svg-icon>
                            </b-button>
                        </div>
                    </b-field>
                </div>
                <b-button v-if="indexArray > 0" class="remove-frame" type="is-danger" @click="deleteParam(indexArray)">╳</b-button>
            </div>
            <b-button class="mb-3 add-frame" type="is-success" @click="addAttribute()" :disabled="attrMultiple.values >= attribute.maxSize">+</b-button>
        </div>
        <div v-else>
            <div v-for="(field, index) in attribute.fields" :key="`${templateType}-${index}`">
                <InputWithValidation v-if="field.type == 'text'" class="mb-4" name="title" :rules="`${ field.required ? 'required|' : ''}min:2`" type="text" :label="$t(field.key)" v-model="attrSingle.fields[index].value" />

                <b-field v-if="field.type == 'editor'" class="mb-4" :label="$t('text')">
                    <vue-editor v-model="attrSingle.fields[index].value"></vue-editor>
				</b-field>

                <b-field v-if="field.type == 'image'" class="mb-3 file">
                    <!-- <b-upload v-model="attrSingle.fields[index].value">
                        <span class="file-cta">
                            <span class="file-label">
                                {{ $t('file') }}
                            </span>
                        </span>
                    </b-upload> -->
                    <div class="avatar">
                        <cropper
                            :src="attrSingle.fields[index].image"
                            @change="changeSingle(index, ...arguments)"
                        />
                        <input type="file" :id="`img-single-${index}`" hidden accept="image/*" @change="onFileChangeSingle(index)" />
                        <b-button
                            v-if="attrSingle.fields[index].added"
                            native-type="button"
                            class="avatar__edit"
                            @click="choosePhotoSingle(index)">
                            <svg-icon icon="edit"></svg-icon>
                        </b-button>
                        <b-button
                            v-if="!attrSingle.fields[index].added"
                            native-type="button"
                            class="avatar__add"
                            @click="choosePhotoSingle(index)">
                            <svg-icon icon="upload"></svg-icon>
                        </b-button>
                    </div>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import { screenAttributesFields } from '@/services/constants'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ToastProgrammatic as Toast } from 'buefy'
import Icon from '@/components/Icon'
import { Cropper } from 'vue-advanced-cropper'
import { VueEditor } from 'vue2-editor'

export default {
    name: 'ScreenAttribute',
    components: {
		InputWithValidation,
        'svg-icon': Icon,
        Cropper,
        VueEditor
	},
    props: {
        templateType: {
            type: [String, Number],
            required: true
        },
        attributesTemp: {
            required: false
        }
    },
    data() {
        return {
            attributesFields: screenAttributesFields,
            attribute: null,
            hide: {
                title: {
                    ol: true,
                    ul: true,
                    url: true,
                    table: true
                },
                text: {}
            },
            attrMultiple: {},
            attrSingle: {},
            sizeMultiple: 0
        }
    },
    mounted() {
        this.attribute = this.attributesFields[this.templateType]

        this.setTypeData()
    },
    watch: {
        templateType(v) {
            this.attribute = this.attributesFields[v]

            this.setTypeData()
        },
        attrMultiple: {
            handler(v) {
                this.$emit('input', v)
            },
            deep: true
        },
        attrSingle: {
            handler(v) {
                this.$emit('input', v)
            },
            deep: true
        }
    },
    methods: {
        deleteParam(index) {
            this.attrMultiple.values.splice(index, 1)
        },
        setTypeData() {
            if (this.attribute.multiple) {
                this.attrMultiple = {
                    multiple: true,
                    values: []
                }
                this.addAttribute()
            } else {
                this.attrSingle = {}
                this.attrSingle = this.attribute
            }

            if (this.attributesTemp) {
                this.setValues()
            }
        },
        addAttribute() {
            if (this.attrMultiple.values.length == 3) {
                Toast.open({
                    duration: 10000,
                    message: 'Em alguns casos, inserir <strong>mais de 4</strong> itens pode desconfigurar seu slide.',
                    type: 'is-warning',
                    position: 'is-bottom'
                })
            }
            if (this.attrMultiple.values.length < this.attribute.maxSize) {
                this.attrMultiple.values.push(JSON.parse(JSON.stringify(this.attribute.fields)))
            } else {
                Toast.open({
                    duration: 5000,
                    message: 'Você atingiu o <strong>limite máximo</strong> de blocos.',
                    type: 'is-danger',
                    position: 'is-bottom'
                })
            }
        },
        setValues() {
            if (this.attributesTemp.multiple) {
                this.attributesTemp.values.forEach((t, i) => {
                    if (t && i > 0) {
                        this.addAttribute()
                    }
                })

                this.attributesTemp.values.forEach((v, i) => {
                    v.forEach(f1 => {
                        this.attrMultiple.values[i].forEach(f2 => {
                            if (f1.key == f2.key) {
                                if (f1.type == 'image') {
                                    f2.file_url = f1.value
                                } else {
                                    f2.value = f1.value
                                }
                            }
                        })
                    })
                })
            } else {
                this.attributesTemp.fields.forEach(f1 => {
                    this.attrSingle.fields.forEach(f2 => {
                        if (f1.key == f2.key) {
                            if (f1.type == 'image') {
                                f2.file_url = f1.value
                            } else {
                                f2.value = f1.value
                            }
                        }
                    })
                })
            }
        },
        choosePhotoSingle(indexField) {
			document.getElementById(`img-single-${indexField}`).click()
		},
        choosePhoto(indexArray, indexField) {
			document.getElementById(`img-${indexArray}-${indexField}`).click()
		},
        async onFileChangeSingle(indexField) {
            const file = document.getElementById(`img-single-${indexField}`).files[0]

            this.attrSingle.fields[indexField].value = file

            this.$set(this.attrSingle.fields[indexField], 'image', await this.readFile(file))
            this.$set(this.attrSingle.fields[indexField], 'added', true)
		},
        async onFileChange(indexArray, indexField) {
            const file = document.getElementById(`img-${indexArray}-${indexField}`).files[0]

            this.attrMultiple.values[indexArray][indexField].value = file

            this.$set(this.attrMultiple.values[indexArray][indexField], 'image', await this.readFile(file))
            this.$set(this.attrMultiple.values[indexArray][indexField], 'added', true)
		},
		changeSingle(indexField, { coordinates }) {
            this.attrSingle.fields[indexField].crop = {}

            this.attrSingle.fields[indexField].crop.width = coordinates.width
			this.attrSingle.fields[indexField].crop.height = coordinates.height
			this.attrSingle.fields[indexField].crop.x = coordinates.left
			this.attrSingle.fields[indexField].crop.y = coordinates.top
		},
        change(indexArray, indexField, { coordinates }) {
            this.attrMultiple.values[indexArray][indexField].crop = {}

            this.attrMultiple.values[indexArray][indexField].crop.width = coordinates.width
			this.attrMultiple.values[indexArray][indexField].crop.height = coordinates.height
			this.attrMultiple.values[indexArray][indexField].crop.x = coordinates.left
			this.attrMultiple.values[indexArray][indexField].crop.y = coordinates.top
		},
        readFile(file) {
			return new Promise(resolve => {
			    const reader = new FileReader()
			    reader.addEventListener('load', () => resolve(reader.result), false)
			    reader.readAsDataURL(file)
			})
		},
        getAspectRatio() {
            if (this.templateType == 8) {
                return { aspectRatio: 1/1 }
            }

            return {}
        }
    }
}
</script>
