<template>
	<article class="block image placeholder">
		<div class="block__cover">
			<b-skeleton width="100%" height="100px"></b-skeleton>
		</div>
		<div class="block__content">
			<h3 class="block__name">
				<b-skeleton width="40%" height="15"></b-skeleton>
			</h3>
			<p class="block__email">
				<b-skeleton width="80%" height="10"></b-skeleton>
			</p>
		</div>
	</article>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
